export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/admin/erro/Erro.vue'),
    meta: {
      title: 'Erro | LevelMember',
      layout: 'default-layout'
    }
  },
  {
    path: '/produtores',
    name: 'produtores',
    component: () => import('../components/admin/produtores/Produtores.vue'),
    meta: {
      logged: true,
      title: 'Produtores | LevelMember',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/areas',
    name: 'areas',
    component: () => import('../components/admin/areas/Areas.vue'),
    meta: {
      logged: true,
      title: 'Áreas na Level | LevelMember',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/dados',
    name: 'dados',
    component: () => import('../components/admin/dados/Dados.vue'),
    meta: {
      logged: true,
      title: 'Dados da Unic | LevelMember',
      layout: 'navbar-layout'
    }
  }
]
