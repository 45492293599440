<template>
  <div id="dados">
    <SectionDados />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStorePerfil, useStoreProdutores } from '@stores'
import Footer from '@/components/global/footer/Footer.vue'
import SectionDados from './partials/SectionDados.vue'

const storePerfil = useStorePerfil()
const storeProdutores = useStoreProdutores()

function carregarPerfil() {
  storePerfil.receberPerfilAdministrador()
}

function carregarProdutores() {
  storeProdutores.receberTodos()
}

onMounted(() => {
  carregarPerfil()
  carregarProdutores()
})
</script>

<style scoped>
#dados {
  padding: 60px 0 0 0;
}
</style>
