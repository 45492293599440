<template>
  <div id="areas">
    <SectionLista />
    <Footer />
  </div>
</template>

<script setup>
import SectionLista from './partials/SectionLista.vue'
import Footer from '@/components/global/footer/Footer.vue'

import { onMounted } from 'vue'
import { useStorePerfil } from '@stores'

const storePerfil = useStorePerfil()

function carregarPerfil() {
  storePerfil.receberPerfilAdministrador()
}

onMounted(() => {
  carregarPerfil()
})
</script>

<style scoped>
#areas {
  padding: 60px 0 0 0;
}
</style>
