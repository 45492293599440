import { api } from './axios'
import { helperToken } from '../../helpers/index'

const token = helperToken.receberToken()

export default {
  receberTodos() {
    const method = 'GET'
    const url = 'produtores'

    return api(method, url, null, token)
  },

  receberPorId(idProdutor) {
    const method = 'GET'
    const url = `produtores/${idProdutor}`

    return api(method, url, null, token)
  },

  resetarSenha(idProdutor) {
    const method = 'PATCH'
    const url = `produtores/senha/resetar/${idProdutor}`

    return api(method, url, {}, token)
  }
}
