import { api } from './axios'
import { helperToken } from '@helpers'

const token = helperToken.receberToken()

export default {
  receberPorProdutor(idProdutor) {
    if (!idProdutor) return

    const method = 'GET'
    const url = `areas/${idProdutor}`

    return api(method, url, null, token)
  },

  receberTodos() {
    const method = 'GET'
    const url = 'areas'

    return api(method, url, null, token)
  }
}
