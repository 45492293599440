import { defineStore } from 'pinia'
import { useStoreAlerta } from './index'
import apiProdutores from '../api/levelmember/api-produtores'

export const useStoreProdutores = defineStore('storeProdutores', {
  state: () => {
    return {
      produtores: [],
      produtor: [],
      produtoresBackup: [],
      modals: {
        idProdutor: null,
        email: null,
        imagemPerfil: null
      },
      mostrarModalAcesso: false,
      mostrarModalResetarSenha: false,
      mostrarModalSites: false,
      mostrarModalBloquearAcesso: false
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiProdutores.receberTodos()
        this.produtores = resp.data.body
        return true
      } catch (error) {
        this.produtores = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorId(idProdutor) {
      try {
        const resp = await apiProdutores.receberPorId(idProdutor)
        this.produtor = resp.data.body
        return true
      } catch (error) {
        this.produtor = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async resetarSenha(idProdutor) {
      try {
        const resp = await apiProdutores.resetarSenha(idProdutor)
        useStoreAlerta().exibirSucessoRequest(resp.data.body)
        return true
      } catch (error) {
        this.produtor = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
