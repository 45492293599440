import { api } from './axios'
import { helperToken } from '../../helpers/index'

const token = helperToken.receberToken()

export default {
  ativarAssinatura(idProdutor, payload) {
    if (!idProdutor) return

    const method = 'PATCH'
    const url = `assinaturas/ativar/${idProdutor}`

    return api(method, url, payload, token)
  },

  desativarAssinatura(idProdutor) {
    if (!idProdutor) return

    const method = 'PATCH'
    const url = `assinaturas/desativar/${idProdutor}`

    return api(method, url, {}, token)
  }
}
