<template>
  <Modal nome="perfilProdutor">
    <div class="info">
      <div class="foto" :style="`background-image: url('${storeProdutores.produtor?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
      <h3>{{ storeProdutores.produtor?.nome }}</h3>
    </div>
    <div class="lista">
      <div class="item">
        <Svgs nome="check" />
        <h3>ID</h3>
        <p>{{ storeProdutores.produtor?._id }}</p>
      </div>
      <div class="item">
        <Svgs nome="envelope" />
        <h3>E-mail</h3>
        <p>{{ storeProdutores.produtor?.email }}</p>
      </div>
      <div class="item">
        <Svgs nome="whatsapp" />
        <h3>Whatsapp</h3>
        <p>{{ storeProdutores.produtor?.contato?.whatsapp }}</p>
        <button @click="abrirWhatsapp(storeProdutores.produtor?.contato?.whatsapp)">Abrir</button>
      </div>
      <div class="item">
        <Svgs nome="link" />
        <h3>origem</h3>
        <p>{{ storeProdutores.produtor?.origem || 'Sem origem' }}</p>
      </div>
      <div class="item">
        <Svgs nome="relogio" />
        <h3>Último Acesso</h3>
        <p>{{ formatarData(storeProdutores.produtor?.dataUltimoAcesso) }}</p>
      </div>
      <div class="item">
        <Svgs nome="calendario" />
        <h3>Registro</h3>
        <p>{{ formatarData(storeProdutores.produtor?.dataRegistro) }}</p>
      </div>
    </div>
    <div class="assinatura">
      <div class="icone" :class="storeProdutores.produtor?.assinatura?.plano">
        <img :src="`https://arquivos.levelmember.com/imagens/planos/${storeProdutores.produtor?.assinatura?.plano}-ico.svg`" />
      </div>
      <h3>Plano {{ storeProdutores.produtor?.assinatura?.plano }}</h3>
      <p>Assinatura {{ storeProdutores.produtor?.assinatura?.frequencia }}</p>
      <p>vencimento {{ formatarDataPlano(storeProdutores.produtor?.assinatura?.dataVencimento) }}</p>
      <div class="estado" :class="{ ativo: storeProdutores.produtor?.assinatura?.ativo }">
        <p>{{ storeProdutores.produtor?.assinatura?.ativo ? 'Ativo' : 'Bloqueado' }}</p>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { reactive, inject, computed, watch, onMounted } from 'vue'
import { useStoreProdutores, useStoreModal } from '@stores'
import Modal from '@/components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeProdutores = useStoreProdutores()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('perfilProdutor'))

const state = reactive({
  confirmar: false,
  id: ''
})

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') fecharModal()
})

async function carregarId() {
  if (dadosModal.value.id) {
    await storeProdutores.receberPorId(dadosModal.value.id)
  }
}

const abrirWhatsapp = (numero) => {
  if (!numero) return

  const numeroFormatado = numero.replace(/\D/g, '')

  const numeroFinal = numeroFormatado.startsWith('55') ? numeroFormatado : `55${numeroFormatado}`

  const link = `https://wa.me/${numeroFinal}`

  window.open(link, '_blank')
}

function formatarData(dataISO) {
  const data = new Date(dataISO)
  const agora = new Date()
  const diferencaMs = agora - data
  const segundos = Math.floor(diferencaMs / 1000)
  const minutos = Math.floor(segundos / 60)
  const horas = Math.floor(minutos / 60)
  const dias = Math.floor(horas / 24)

  if (dias > 7) {
    return `${data.getDate().toString().padStart(2, '0')}/${(data.getMonth() + 1).toString().padStart(2, '0')}/${data.getFullYear()}`
  } else if (dias > 0) {
    return `há ${dias} dia${dias > 1 ? 's' : ''}`
  } else if (horas > 0) {
    return `há ${horas} hora${horas > 1 ? 's' : ''}`
  } else if (minutos > 0) {
    return `há ${minutos} minuto${minutos > 1 ? 's' : ''}`
  } else {
    return `agora mesmo`
  }
}

function formatarDataPlano(dataISO) {
  const data = new Date(dataISO)

  return `${data.getDate().toString().padStart(2, '0')}/${(data.getMonth() + 1).toString().padStart(2, '0')}/${data.getFullYear()}`
}

function limparCampos() {
  state.id = ''
  state.confirmar = false
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        carregarId()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 20px 0;
}
.info .foto {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 0 0 10px 0;
}
.info h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}
.lista {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.lista .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--cor-cinza-3);
  padding: 15px 0;
}
.lista .item svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-cinza-5);
  margin: 0 10px 0 0;
}
.lista .item h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin-right: auto;
}
.lista .item p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}
.lista .item button {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-verde);
  background-color: #23bc5820;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}
.lista .item button:hover {
  background-color: #23bc5840;
}
.assinatura {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 30px;
  border: 1px solid var(--cor-cinza-3);
}

.assinatura .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
}

.assinatura .icone img {
  width: 100%;
  max-width: 40px;
}

.assinatura h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 15px 0 5px 0;
}

.assinatura p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
}

.assinatura .estado {
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #f53a3a34;
  margin: 10px 0 0 0;
}

.assinatura .estado.ativo {
  background-color: #22bc5832;
}

.assinatura .estado.ativo p {
  color: var(--cor-verde);
}

.assinatura .estado p {
  font-size: var(--f2);
  font-family: var(--bold);
  color: var(--cor-vermelho);
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.modal {
    align-items: flex-start;
    padding: 10px;
  }
  section.modal .titulo {
    padding: 20px;
  }
  section.modal .botao {
    padding: 20px;
  }
  section.modal .campos {
    padding: 20px 20px 0 20px;
  }
}
</style>
