import { defineStore } from 'pinia'
import { useStoreAlerta } from './index'
import apiAreas from '../api/levelmember/api-areas'

export const useStoreAreas = defineStore('storeAreas', {
  state: () => {
    return {
      areasProdutor: [],
      areas: []
    }
  },
  actions: {
    async receberPorProdutor(idProdutor) {
      try {
        const resp = await apiAreas.receberPorProdutor(idProdutor)
        this.areasProdutor = resp.data.body
        return true
      } catch (error) {
        this.areasProdutor = []
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberTodos() {
      try {
        const resp = await apiAreas.receberTodos()
        this.areas = resp.data.body
        return true
      } catch (error) {
        this.areas = []
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
