import { defineStore } from 'pinia'
import { useStoreAlerta } from './index'
import apiAssinaturas from '../api/levelmember/api-assinaturas'

export const useStoreAssinaturas = defineStore('storeAssinaturas', {
  state: () => {
    return {}
  },
  actions: {
    async ativarAssinatura(idUsuario, payload) {
      try {
        const resp = await apiAssinaturas.ativarAssinatura(idUsuario, payload)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async desativarAssinatura(idUsuario) {
      try {
        const resp = await apiAssinaturas.desativarAssinatura(idUsuario)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
