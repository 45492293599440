import { defineStore } from 'pinia'
import { useStoreAlerta } from './index'
import apiAdministradores from '../api/levelmember/api-administradores'

export const useStorePerfil = defineStore('storePerfil', {
  state: () => {
    return {
      usuario: null,
      mostrarModalEditarPerfil: false,
      mostrarModalAlterarSenha: false,
      modalVisualizarUsuarioAberto: false
    }
  },
  actions: {
    async receberPerfilAdministrador() {
      try {
        const resp = await apiAdministradores.receberPerfilAdministrador()
        this.usuario = resp.data.body
        return true
      } catch (error) {
        this.usuario = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(payload) {
      try {
        const resp = await apiAdministradores.alterarDados(payload)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarSenha(payload) {
      try {
        const resp = await apiAdministradores.alterarSenha(payload)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
