<template>
  <div id="produtores">
    <ModalAcesso />
    <ModalResetarSenha />
    <ModalAreas />
    <ModalBloquearAcesso />
    <ModalPerfil />
    <SectionLista />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStorePerfil } from '@stores'
import ModalAcesso from './modals/ModalAcesso.vue'
import ModalResetarSenha from './modals/ModalResetarSenha.vue'
import SectionLista from './partials/SectionLista.vue'
import Footer from '@/components/global/footer/Footer.vue'
import ModalAreas from './modals/ModalAreas.vue'
import ModalPerfil from './modals/ModalPerfil.vue'
import ModalBloquearAcesso from './modals/ModalBloquearAcesso.vue'

const storePerfil = useStorePerfil()

function carregarPerfil() {
  storePerfil.receberPerfilAdministrador()
}

onMounted(() => {
  carregarPerfil()
})
</script>

<style scoped>
#produtores {
  padding: 60px 0 0 0;
}
</style>
